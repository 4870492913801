import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/systemLog/getList',
    method: 'get',
    params,
  })
}

/**
 * @description: 获取操作日志列表
 * @param {*} params
 * @return {*}
 */
export function getOperationLog(params) {
  return request({
    url: `/iam/operation/log/query`,
    method: 'get',
    params,
  })
}

/**
 * @description: 获取操作日志类型列表
 * @param {*} params
 * @return {*}
 */
export function getOperationTypeList(params) {
  return request({
    url: `/iam/operation/log/type/query`,
    method: 'get',
    params,
  })
}
